// src/pages/Settings.js
import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import Services from '../components/Services';
import Positions from '../components/Positions';
import Mechanics from '../components/Mechanics';
import WorkSchedule from '../components/WorkSchedule';
import RecordingLog from '../components/RecordingLog';

function Settings() {
  const [selectedSection, setSelectedSection] = useState('services');
  const [positions, setPositions] = useState([]); // Store positions here
  const [mechanics, setMechanics] = useState([]); // Store mechanics here

  // Function to handle adding positions
  const handleAddPosition = (newPosition) => {
    setPositions([...positions, newPosition]);
  };

  // Function to handle adding mechanics
  const handleAddMechanic = (newMechanic) => {
    setMechanics([...mechanics, newMechanic]);
  };

  const renderContent = () => {
    switch (selectedSection) {
      case 'services':
        return <Services />;
      case 'positions':
        return <Positions positions={positions} handleAddPosition={handleAddPosition} />;
      case 'mechanics':
        return <Mechanics positions={positions} mechanics={mechanics} handleAddMechanic={handleAddMechanic} />;
      case 'work-schedule':
        return <WorkSchedule />;
      case 'recording-log':
        return <RecordingLog />;
      default:
        return <Typography>Select a section</Typography>;
    }
  };

  return (
    <Box display="flex">
      {/* Sidebar */}
      <Drawer
        variant="permanent"
        sx={{ width: 240, flexShrink: 0 }}
        PaperProps={{ sx: { width: 240 } }}
      >
        <Box p={2}>
          <Typography variant="h6">Settings</Typography>
          <List>
            <ListItem button onClick={() => setSelectedSection('services')}>
              <ListItemText primary="Услуги" />
            </ListItem>
            <ListItem button onClick={() => setSelectedSection('positions')}>
              <ListItemText primary="Должность" />
            </ListItem>
            <ListItem button onClick={() => setSelectedSection('mechanics')}>
              <ListItemText primary="Сотрудник" />
            </ListItem>
            <ListItem button onClick={() => setSelectedSection('work-schedule')}>
              <ListItemText primary="График работы" />
            </ListItem>
            {/*<ListItem button onClick={() => setSelectedSection('recording-log')}>*/}
            {/*  <ListItemText primary="Recording Log" />*/}
            {/*</ListItem>*/}
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {renderContent()}
      </Box>
    </Box>
  );
}

export default Settings;
