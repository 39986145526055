// src/components/RecordingLog.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function RecordingLog() {
  return (
    <Box>
      <Typography variant="h6">Recording Log</Typography>
      {/* Add functionality to view and manage recording logs here */}
    </Box>
  );
}

export default RecordingLog;
