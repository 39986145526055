// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    // Если токен отсутствует, перенаправляем на страницу входа
    if (!token) {
        return <Navigate to="/login" />;
    }

    // Если токен есть, возвращаем детей (доступ к защищённому маршруту)
    return children;
};

export default ProtectedRoute;