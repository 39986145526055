// src/pages/LogoutPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import the AuthContext

const LogoutPage = () => {
    const navigate = useNavigate();
    const { logout } = useAuth(); // Get the logout function from context

    const handleLogout = () => {
        logout(); // Call the logout function from context
        navigate('/login'); // Redirect to the login page
    };

    return (
        <div>
            <h1>Вы вышли из системы</h1>
            <p>Вы успешно вышли из своей учетной записи.</p>
            <button onClick={handleLogout}>Вернуться на страницу входа</button>
        </div>
    );
};

export default LogoutPage;