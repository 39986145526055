// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Calendar from './pages/Calendar';
import Clients from './pages/Clients';
import Mechanics from './pages/Mechanics';
import Settings from './pages/Settings';
import Register from './pages/Register';
import Login from './pages/Login';
import logout from './pages/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import {AuthProvider} from "./contexts/AuthContext";
import Logout from "./pages/Logout"; // Импортируем компонент ProtectedRoute

function App() {
  return (
      <AuthProvider><Router>
        <div>
          <Navbar/>
          <Routes>
            <Route path="/" element={<ProtectedRoute><Calendar/></ProtectedRoute>}/>
            <Route path="/clients" element={<ProtectedRoute><Clients/></ProtectedRoute>}/>
            <Route path="/settings" element={<ProtectedRoute><Settings/></ProtectedRoute>}/>
            {/*<Route path="/register" element={<Register/>}/>*/}
            <Route path="/login" element={<Login/>}/>
             <Route path="/logout" element={<Logout/>}/>
          </Routes>
        </div>
      </Router></AuthProvider>
  );
}

export default App;