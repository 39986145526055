import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function Clients() {
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [newClient, setNewClient] = useState({ name: '', phoneNumber: '', carBrand: '', carModel: '', stateNumber: '' });
    const [searchQueries, setSearchQueries] = useState({
        name: '',
        phoneNumber: '',
        carBrand: '',
        carModel: '',
        stateNumber: '' // Add this
    });

    // Fetch clients on load and when search queries change
    useEffect(() => {
        fetchClients();
    }, [searchQueries]);

    const fetchClients = () => {
        const token = localStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${apiUrl}/api/clients`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => setClients(data))
            .catch(error => console.error('Error fetching clients:', error));
    };



    // Handle add or update client
    const handleSaveClient = () => {
        if (newClient.name && newClient.phoneNumber && newClient.carBrand && newClient.carModel && newClient.stateNumber) {
            const token = localStorage.getItem('token');
            const method = selectedClient ? 'PUT' : 'POST';
            const apiUrl = process.env.REACT_APP_API_BASE_URL;

            const url = selectedClient ? `${apiUrl}/api/clients/${selectedClient.id}` : `${apiUrl}/api/clients`;

            fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },        body: JSON.stringify(newClient),
            })
                .then(response => response.json())
                .then(data => {
                    const updatedClient = {
                        id: data.id,
                        name: data.name,
                        phone_number: data.phoneNumber,
                        car_brand: data.carBrand,
                        car_model: data.carModel,
                        state_number: data.stateNumber, // Include this
                    };

                    if (selectedClient) {
                        // Update existing client in the state
                        setClients(clients.map(client => (client.id === selectedClient.id ? updatedClient : client)));
                    } else {
                        // Add new client to the state
                        setClients([...clients, updatedClient]);
                    }

                    setNewClient({ name: '', phoneNumber: '', carBrand: '', carModel: '', stateNumber: '' });
                    setSelectedClient(null);
                    handleClose();
                })
                .catch(error => console.error('Error saving client:', error));
        } else {
            alert('All fields are required');
        }
    };


    // Handle search input changes
    const handleSearchChange = (field, value) => {
        setSearchQueries({ ...searchQueries, [field]: value });
    };

    // Handle row click for editing
    const handleRowClick = (client) => {
        setSelectedClient(client);
        setNewClient({
            name: client.name,
            phoneNumber: client.phone_number,
            carBrand: client.car_brand,
            carModel: client.car_model,
        });
        setOpen(true);
    };

    // Handle delete client
    const handleDeleteClient = (clientId) => {
        const token = localStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${apiUrl}/api/clients/${clientId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(() => {
                setClients(clients.filter(client => client.id !== clientId));
            })
            .catch(error => console.error('Error deleting client:', error));
    };

    // Modal handlers
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSelectedClient(null);  // Clear selected client on modal close
        setNewClient({ name: '', phoneNumber: '', carBrand: '', carModel: '' });  // Reset the form
        setOpen(false);
    };


    return (
        <Box>
            <Typography variant="h6" mb={2}>Clients</Typography>

            {/* Search Fields */}
            <Box display="flex" gap={2} mb={2}>
                <TextField
                    label="Поиск имя"
                    variant="outlined"
                    value={searchQueries.name}
                    onChange={(e) => handleSearchChange('name', e.target.value)}
                />
                <TextField
                    label="Поиск номер телефона"
                    variant="outlined"
                    value={searchQueries.phoneNumber}
                    onChange={(e) => handleSearchChange('phoneNumber', e.target.value)}
                />
                <TextField
                    label="Поиск марка машины"
                    variant="outlined"
                    value={searchQueries.carBrand}
                    onChange={(e) => handleSearchChange('carBrand', e.target.value)}
                />
                <TextField
                    label="Поиск модель машины"
                    variant="outlined"
                    value={searchQueries.carModel}
                    onChange={(e) => handleSearchChange('carModel', e.target.value)}
                />
                <TextField
                    label="Поиск гос номер"
                    variant="outlined"
                    value={searchQueries.stateNumber}
                    onChange={(e) => handleSearchChange('stateNumber', e.target.value)}
                />

            </Box>

            <Button variant="contained" color="primary" onClick={handleOpen}>Добавить клиента</Button>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя</TableCell>
                            <TableCell>Номер телефона</TableCell>
                            <TableCell>Марка машины</TableCell>
                            <TableCell>Модель машины</TableCell>
                            <TableCell>Гос номер</TableCell> {/* Add this */}
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clients.map(client => (
                            <TableRow key={client.id} onClick={() => handleRowClick(client)}>
                                <TableCell>{client.name}</TableCell>
                                <TableCell>{client.phone_number}</TableCell>
                                <TableCell>{client.car_brand}</TableCell>
                                <TableCell>{client.car_model}</TableCell>
                                <TableCell>{client.state_number}</TableCell> {/* Add this */}
                                <TableCell>
                                    <Button color="secondary" onClick={(e) => { e.stopPropagation(); handleDeleteClient(client.id); }}>
                                        Удалить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

            {/* Modal for adding/editing clients */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    p={3}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" mb={2}>{selectedClient ? 'Редактировать Клиента' : 'Добавить нового клиента'}</Typography>
                    <TextField
                        label="Имя"
                        fullWidth
                        value={newClient.name}
                        onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label="Номер телефона"
                        fullWidth
                        value={newClient.phoneNumber}
                        onChange={(e) => setNewClient({ ...newClient, phoneNumber: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label="Марка машины"
                        fullWidth
                        value={newClient.carBrand}
                        onChange={(e) => setNewClient({ ...newClient, carBrand: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label="Модель машины"
                        fullWidth
                        value={newClient.carModel}
                        onChange={(e) => setNewClient({ ...newClient, carModel: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label="Гос номер"
                        fullWidth
                        value={newClient.stateNumber}
                        onChange={(e) => setNewClient({ ...newClient, stateNumber: e.target.value })}
                        margin="normal"
                    />

                    <Button variant="contained" color="primary" onClick={handleSaveClient} sx={{ mt: 2 }}>
                        {selectedClient ? 'Обновить клиента' : 'Добавить клиента'}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default Clients;
