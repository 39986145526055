import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Positions() {
    const [positions, setPositions] = useState([]);
    const [open, setOpen] = useState(false);
    const [newPosition, setNewPosition] = useState({ title: '', description: '' });

    // Fetch positions from the backend when the component loads
    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${apiUrl}/api/positions`, {
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPositions(data);
            })
            .catch((error) => {
                console.error('Error fetching positions:', error);
            });
    }, []);


    // Handle modal open/close
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Handle adding a new position
    const handleAddPosition = () => {
        if (newPosition.title) { // Only validate title
            const token = localStorage.getItem('token'); // Получаем токен из localStorage
            const apiUrl = process.env.REACT_APP_API_BASE_URL;

            fetch(`${apiUrl}/api/positions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                },
                body: JSON.stringify(newPosition),
            })
                .then((response) => response.json())
                .then((data) => {
                    setPositions([...positions, data]);
                    setNewPosition({ title: '', description: '' }); // Reset input fields
                    handleClose();
                })
                .catch((error) => {
                    console.error('Error saving position:', error);
                });
        } else {
            alert('Title is required');
        }
    };


    // Handle deleting a position
    const handleDeletePosition = (id) => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${apiUrl}/api/positions/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
            },
        })
            .then((response) => {
                if (response.ok) {
                    setPositions(positions.filter((position) => position.id !== id));
                } else {
                    console.error('Failed to delete position');
                }
            })
            .catch((error) => {
                console.error('Error deleting position:', error);
            });
    };


    return (
        <Box>
            <Typography variant="h6" mb={2}>Должность</Typography>
            <Button variant="contained" color="primary" onClick={handleOpen}>Добавить должность</Button>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Наименование должности</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {positions.map((position) => (
                            <TableRow key={position.id}>
                                <TableCell>{position.id}</TableCell>
                                <TableCell>{position.title}</TableCell>
                                <TableCell>{position.description}</TableCell>
                                <TableCell>
                                    <IconButton color="error" onClick={() => handleDeletePosition(position.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for adding new positions */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    p={3}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" mb={2}>Добавить новую должность</Typography>
                    <TextField
                        label="Наименование должности"
                        fullWidth
                        value={newPosition.title}
                        onChange={(e) => setNewPosition({ ...newPosition, title: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label="Описание"
                        fullWidth
                        multiline
                        rows={4}
                        value={newPosition.description}
                        onChange={(e) => setNewPosition({ ...newPosition, description: e.target.value })}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddPosition} sx={{ mt: 2 }}>
                        Добавить должность
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default Positions;
