import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Mechanics() {
  const [mechanics, setMechanics] = useState([]);
  const [positions, setPositions] = useState([]);
  const [open, setOpen] = useState(false);
  const [newMechanic, setNewMechanic] = useState({ name: '', position_id: '' });

  // Fetch mechanics and positions from the backend
    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/mechanics`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then((response) => response.json())
            .then((data) => setMechanics(data))
            .catch((error) => console.error('Error fetching mechanics:', error));

        fetch(`${apiUrl}/api/positions`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then((response) => response.json())
            .then((data) => setPositions(data))
            .catch((error) => console.error('Error fetching positions:', error));
    }, []);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    const handleAddMechanic = () => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        if (newMechanic.name) {
            fetch(`${apiUrl}/api/mechanics`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
                },
                body: JSON.stringify(newMechanic),
            })
                .then((response) => response.json())
                .then((data) => {
                    setMechanics([...mechanics, data]);
                    setNewMechanic({ name: '', position_id: '' });
                    handleClose();
                })
                .catch((error) => console.error('Error adding mechanic:', error));
        } else {
            alert('Name is required');
        }
    };

    const handleDeleteMechanic = (id) => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${apiUrl}/api/mechanics/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then((response) => {
                if (response.ok) {
                    setMechanics(mechanics.filter((mechanic) => mechanic.id !== id));
                } else {
                    console.error('Failed to delete mechanic');
                }
            })
            .catch((error) => console.error('Error deleting mechanic:', error));
    };


  return (
    <Box>
      <Typography variant="h6" mb={2}>Сотрудники</Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>Добавить сотрудника</Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Должность</TableCell>
              <TableCell>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mechanics.map((mechanic) => (
              <TableRow key={mechanic.id}>
                <TableCell>{mechanic.id}</TableCell>
                <TableCell>{mechanic.name}</TableCell>
                <TableCell>{mechanic.position}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => handleDeleteMechanic(mechanic.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for adding new mechanics */}
      <Modal open={open} onClose={handleClose}>
        <Box
          p={3}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>Добавить нового сотрудника</Typography>
          <TextField
            label="Имя"
            fullWidth
            value={newMechanic.name}
            onChange={(e) => setNewMechanic({ ...newMechanic, name: e.target.value })}
            margin="normal"
          />
          <TextField
            label=""
            select
            SelectProps={{ native: true }}
            fullWidth
            value={newMechanic.position_id}
            onChange={(e) => setNewMechanic({ ...newMechanic, position_id: e.target.value })}
            margin="normal"
          >
            <option value="">None</option>
            {positions.map((position) => (
              <option key={position.id} value={position.id}>
                {position.title}
              </option>
            ))}
          </TextField>
          <Button variant="contained" color="primary" onClick={handleAddMechanic} sx={{ mt: 2 }}>
            Добавить сотрудника
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Mechanics;
