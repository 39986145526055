import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Services() {
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [newService, setNewService] = useState({ name: '', category: '' });

    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/services`, {
            headers: {
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setServices(data);
            })
            .catch((error) => {
                console.error('Error fetching services:', error);
            });
    }, []);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    const handleAddService = () => {
        if (newService.name && newService.category) {
            const token = localStorage.getItem('token'); // Получаем токен из localStorage
            const apiUrl = process.env.REACT_APP_API_BASE_URL;

            fetch(`${apiUrl}/api/services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                },
                body: JSON.stringify(newService),
            })
                .then((response) => response.json())
                .then((data) => {
                    setServices([...services, data]);
                    setNewService({ name: '', category: '' });
                    handleClose();
                })
                .catch((error) => {
                    console.error('Error saving service:', error);
                });
        } else {
            alert('Name and category are required');
        }
    };


    const handleDeleteService = (id) => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${apiUrl}/api/services/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
            },
        })
            .then((response) => {
                if (response.ok) {
                    setServices(services.filter((service) => service.id !== id));
                } else {
                    console.error('Failed to delete service');
                }
            })
            .catch((error) => {
                console.error('Error deleting service:', error);
            });
    };


  return (
    <Box>
      <Typography variant="h6" mb={2}>Услуги</Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>Добавить услугу</Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Категория</TableCell>
              <TableCell>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell>{service.id}</TableCell>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.category}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => handleDeleteService(service.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open} onClose={handleClose}>
        <Box
          p={3}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>Добавить новую услугу</Typography>
          <TextField
            label="Имя"
            fullWidth
            value={newService.name}
            onChange={(e) => setNewService({ ...newService, name: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Категория"
            fullWidth
            value={newService.category}
            onChange={(e) => setNewService({ ...newService, category: e.target.value })}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddService} sx={{ mt: 2 }}>
            Добавить услугу
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Services;
