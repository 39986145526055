import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Box, Modal, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import './Calendar.css';

function Calendar() {
    const [events, setEvents] = useState([]); // Store created tasks
    const [open, setOpen] = useState(false); // Modal visibility
    const [selectedCell, setSelectedCell] = useState({ mechanic: '', time: '', date: '' }); // Selected mechanic, time, and date for the modal
    const [clientDetails, setClientDetails] = useState({ name: '', phoneNumber: '', carBrand: '', carModel: '', stateNumber: '' }); // Client details for autofill
    const [clients, setClients] = useState([]); // Store fetched clients
    const [timeIntervals, setTimeIntervals] = useState([]); // Dynamic time intervals based on working hours
    const [selectedDate, setSelectedDate] = useState(''); // Fix: Add state to store the selected date
    const [mechanics, setMechanics] = useState([]); // State to store fetched mechanics
    const [services, setServices] = useState([]);

    const [newTask, setNewTask] = useState({
        date: selectedCell.date,
        time: selectedCell.time,
        serviceId: '',
        clientId: '',
        comment: ''
    }); // To store task updates

    const [newClient, setNewClient] = useState({
        name: '',
        phoneNumber: '',
        carBrand: '',
        carModel: '',
        stateNumber: ''
    }); // To store new client details

    const [showNewClientForm, setShowNewClientForm] = useState(false); // Toggle new client form visibility

    const [selectedTaskId, setSelectedTaskId] = useState(null);

    // Fetch mechanics from the backend API
    useEffect(() => {
        // console.log('Selected Task ID:', selectedTaskId);
    }, [selectedTaskId]);

    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/mechanics`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then(response => response.json())
            .then(data => {
                setMechanics(data); // Set the fetched mechanics data
            })
            .catch(error => console.error('Error fetching mechanics:', error));
    }, []);


    useEffect(() => {
        if (selectedTaskId) {
            // console.log('Fetching task details for editing');
            // Fetch the task for editing here and update the modal fields
        } else {
            console.log('No task selected, modal is for creating a new task');
        }
    }, [selectedTaskId]);



    // Fetch clients from the backend API
    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/clients`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then(response => response.json())
            .then(data => setClients(data))
            .catch(error => console.error('Error fetching clients:', error));
    }, []);

// Fetch services
    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/services`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
            },
        })
            .then(response => response.json())
            .then(data => setServices(data)) // Store services in state
            .catch(error => console.error('Error fetching services:', error));
    }, []);

    useEffect(() => {
        const today = dayjs().format('YYYY-MM-DD');
        setSelectedDate(today);
        const startDate = dayjs(today).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = dayjs(today).endOf('day').format('YYYY-MM-DD HH:mm:ss');

        // Ensure tasks for today are fetched automatically when the page loads
        fetchTasksForDay(today, startDate, endDate);
    }, []);

    const fetchTasksForDay = async (selectedDateStr) => {
        try {
            const token = localStorage.getItem('token'); // Получаем токен из localStorage
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const startDate = dayjs(selectedDateStr).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const endDate = dayjs(selectedDateStr).endOf('day').format('YYYY-MM-DD HH:mm:ss');

            const [scheduleResponse, tasksResponse] = await Promise.all([
                fetch(`${apiUrl}/api/work-schedule`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
                    },
                }),
                fetch(`${apiUrl}/api/tasks?startDate=${startDate}&endDate=${endDate}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}` // Добавляем заголовок авторизации
                    },
                })
            ]);

            const scheduleData = await scheduleResponse.json();
            const tasksData = await tasksResponse.json();

            const dayOfWeek = dayjs(selectedDateStr).format('dddd');
            const intervals = generateTimeIntervals(scheduleData, dayOfWeek);
            setTimeIntervals(intervals);

            const tasks = tasksData.map(task => ({
                id: task.id,
                mechanicId: task.mechanic_id,
                clientId: task.client_id,
                serviceId: task.service_id,
                start_time: task.start_time,
                end_time: task.end_time,
                comment: task.comment,
                mechanic: task.mechanic_name,
                client: task.client_name,
                service: task.service_name,
            }));

            // Update events based on the fetched tasks and time intervals.
            const newEvents = intervals.map(time => {
                const tasksAtTime = tasks.filter(task => {
                    const taskTime = dayjs(task.start_time).format('HH:mm');
                    const timeRange = time.split(' - ');
                    return taskTime >= timeRange[0] && taskTime < timeRange[1];
                });
                return { time, tasks: tasksAtTime };
            });

            setEvents(newEvents);
        } catch (error) {
            console.error('Error fetching tasks for the day:', error);
        }
    };




    const handleDateSelect = (selectInfo) => {
        const selectedDateStr = selectInfo.dateStr;
        setSelectedDate(selectedDateStr);

        const startDate = dayjs(selectedDateStr).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = dayjs(selectedDateStr).endOf('day').format('YYYY-MM-DD HH:mm:ss');

        fetchTasksForDay(selectedDateStr, startDate, endDate);
    };

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const token = localStorage.getItem('token'); // Получаем токен из localStorage
                const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
                const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
                const apiUrl = process.env.REACT_APP_API_BASE_URL;
                const response = await fetch(`${apiUrl}/api/tasks?startDate=${startDate}&endDate=${endDate}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                    },
                });

                const data = await response.json();

                // Проверяем, является ли data массивом
                if (Array.isArray(data)) {
                    setEvents(data);
                } else {
                    console.error("Fetched tasks data is not an array:", data);
                    setEvents([]);  // Сбрасываем до пустого массива, если данные некорректны
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                setEvents([]); // Сбрасываем до пустого массива при возникновении ошибки
            }
        };
        fetchTasks();
    }, []);


// Запрос для получения рабочего расписания с сервера и генерации временных интервалов
    useEffect(() => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/work-schedule`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    const today = new Date();
                    const dayOfWeek = dayjs(today).format('dddd');
                    const intervals = generateTimeIntervals(data, dayOfWeek);
                    setTimeIntervals(intervals);
                }
            })
            .catch(error => console.error('Error fetching work schedule:', error));
    }, []);





    const generateTimeIntervals = (schedule, dayOfWeek) => {
        const daySchedule = schedule.find(item => item.day === dayOfWeek);
        if (!daySchedule) {
            return [];
        }

        let startTime = daySchedule.start.slice(0, 5);
        let endTime = daySchedule.end.slice(0, 5);
        const intervals = [];
        let currentTime = startTime;

        while (currentTime < endTime) {
            const nextTime = addOneHour(currentTime);
            intervals.push(`${currentTime} - ${nextTime}`);
            currentTime = nextTime;
        }
        return intervals;
    };

    const addOneHour = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const newTime = new Date(0, 0, 0, hours + 1, minutes);
        return newTime.toTimeString().slice(0, 5);
    };

    const handleOpen = (mechanic, time, task) => {
        console.log("Selected Task ID:", task ? task.id : "No task");

        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        setSelectedCell({
            mechanic,
            time,
            mechanicId: mechanic.id,
            date: selectedDate
        });

        if (task) {
            // Редактирование существующей задачи
            setSelectedTaskId(task.id); // Сохраняем ID задачи для редактирования
            fetch(`${apiUrl}/api/tasks/${task.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Fetched Task Data:', data);

                    // Извлекаем время начала и окончания
                    const startTime = new Date(data.start_time); // Создаем объект Date
                    const endTime = new Date(data.end_time);     // Создаем объект Date

                    // Добавляем 2 часа к времени начала и окончания
                    startTime.setHours(startTime.getHours() + 2);
                    endTime.setHours(endTime.getHours() + 2);

                    // Форматируем время начала и окончания как "HH:MM"
                    const formattedStartTime = startTime.toISOString().slice(11, 16); // Получаем "HH:MM"
                    const formattedEndTime = endTime.toISOString().slice(11, 16);     // Получаем "HH:MM"

                    setNewTask({
                        date: data.start_time.split('T')[0], // Устанавливаем дату
                        time: `${formattedStartTime} - ${formattedEndTime}`, // Устанавливаем скорректированное время
                        serviceId: data.service_id,
                        clientId: data.client_id,
                        comment: data.comment,
                    });

                    const selectedClient = clients.find(client => client.id === data.client_id);
                    if (selectedClient) {
                        setClientDetails({
                            name: selectedClient.name,
                            phoneNumber: selectedClient.phone_number,
                            carBrand: selectedClient.car_brand,
                            carModel: selectedClient.car_model,
                            stateNumber: selectedClient.state_number,
                        });
                    } else {
                        console.warn('Client not found in state:', data.client_id);
                    }
                })
                .catch(error => console.error('Error fetching task:', error));
        } else {
            // Создание новой задачи
            setNewTask({
                date: selectedDate,
                time: time, // Используем переданное время напрямую
                serviceId: '',
                clientId: '',
                comment: '',
            });
        }

        setOpen(true); // Открываем модальное окно
    };






    const handleClose = () => {
        setOpen(false);
        setNewTask({ description: '', service: '', clientId: '' });
        setClientDetails({ name: '', phoneNumber: '', carBrand: '', carModel: '', stateNumber: '' });
    };

    const handleClientSelect = (clientId) => {
        const selectedClient = clients.find(client => client.id === clientId);
        if (selectedClient) {
            setClientDetails({
                name: selectedClient.name,
                phoneNumber: selectedClient.phone_number,
                carBrand: selectedClient.car_brand,
                carModel: selectedClient.car_model,
                stateNumber: selectedClient.state_number,
            });
            setNewTask(prevTask => ({ ...prevTask, clientId })); // Update the clientId here
        }
    };


    const formatDateTime = (date, time) => {
        const dateTime = dayjs(`${date} ${time}`);
        return dateTime.isValid() ? dateTime.format('YYYY-MM-DD HH:mm:ss') : null;
    };


    const handleAddTask = () => {
        const { serviceId, comment } = newTask;
        const apiUrl = process.env.REACT_APP_API_BASE_URL;

        if (!serviceId || !newTask.clientId || !selectedCell.mechanicId) {
            alert('Service, client, and mechanic are required');
            return;
        }

        const start = formatDateTime(selectedCell.date, selectedCell.time.split(' - ')[0]);
        const end = formatDateTime(selectedCell.date, addOneHour(selectedCell.time.split(' - ')[0]));

        if (!start || !end) {
            alert('Invalid date or time. Please check your input.');
            return;
        }

        const newEvent = {
            mechanicId: selectedCell.mechanicId,
            clientId: newTask.clientId,
            serviceId,
            start,
            end,
            comment: comment || '',
        };

        fetch(`${apiUrl}/api/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newEvent),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(errorData.error || 'Failed to create task.');
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.id) {
                    setEvents([...events, { ...newEvent, client: clientDetails.name }]);
                    handleClose(); // Close modal after success
                    handleDateSelect({ dateStr: selectedDate }); // Re-fetch tasks for the day
                } else {
                    alert('Failed to create task: Unknown error');
                }
            })
            .catch(error => {
                console.error('Error creating task:', error);
                alert('Failed to create task. Please try again.');
            });
    };


    const handleSaveTask = () => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const updatedTask = {
            date: newTask.date,
            time: newTask.time,
            serviceId: newTask.serviceId,
            clientId: newTask.clientId,
            comment: newTask.comment,
        };

        const startTime = updatedTask.time.split(' - ')[0];
        const endTime = updatedTask.time.split(' - ')[1];
        const start = formatDateTime(updatedTask.date, startTime);
        const end = formatDateTime(updatedTask.date, endTime);

        if (selectedTaskId) { // Обновление существующей задачи
            console.log(`Saving Task: ${selectedTaskId ? 'Updating existing task with ID ' + selectedTaskId : 'Creating new task'}`);
            fetch(`${apiUrl}/api/tasks/${selectedTaskId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                },
                body: JSON.stringify({
                    mechanicId: selectedCell.mechanicId,
                    clientId: updatedTask.clientId,
                    serviceId: updatedTask.serviceId,
                    start,
                    end,
                    comment: updatedTask.comment,
                }),
            })
                .then(response => {
                    if (!response.ok) throw new Error('Failed to update task');
                    return response.json();
                })
                .then(() => {
                    // Обновление задач для старой и новой даты
                    const oldDate = selectedCell.date;
                    if (oldDate !== updatedTask.date) {
                        fetchTasksForDay(oldDate); // Обновление задач для старой даты
                    }
                    fetchTasksForDay(updatedTask.date); // Обновление задач для новой даты
                    handleClose(); // Закрываем модальное окно
                })
                .catch(error => console.error('Error updating task:', error));
        } else { // Создание новой задачи
            fetch(`${apiUrl}/api/tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                },
                body: JSON.stringify({
                    mechanicId: selectedCell.mechanicId,
                    clientId: updatedTask.clientId,
                    serviceId: updatedTask.serviceId,
                    start,
                    end,
                    comment: updatedTask.comment,
                }),
            })
                .then(response => {
                    if (!response.ok) throw new Error('Failed to create task');
                    return response.json();
                })
                .then(() => {
                    fetchTasksForDay(newTask.date); // Обновление задач для новой даты
                    handleClose(); // Закрываем модальное окно
                })
                .catch(error => console.error('Error creating task:', error));
        }
    };


    const handleDeleteTask = () => {
        if (!selectedTaskId) {
            console.error('No task selected for deletion');
            return;
        }

        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/tasks/${selectedTaskId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
            },
        })
            .then(response => {
                if (!response.ok) throw new Error('Failed to delete task');
                // Обновляем список задач для выбранной даты
                fetchTasksForDay(selectedCell.date);
                handleClose(); // Закрываем модальное окно после удаления
            })
            .catch(error => console.error('Error deleting task:', error));
    };



    const handleAddClient = () => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${apiUrl}/api/clients`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
            },
            body: JSON.stringify({
                name: newClient.name,
                phoneNumber: newClient.phoneNumber,
                carBrand: newClient.carBrand,
                carModel: newClient.carModel,
                stateNumber: newClient.stateNumber,
            }),
        })
            .then(response => response.json())
            .then(data => {
                setClients([...clients, data]); // Добавляем нового клиента в список
                setNewClient({
                    name: '',
                    phoneNumber: '',
                    carBrand: '',
                    carModel: '',
                    stateNumber: '',
                }); // Очищаем форму
                setShowNewClientForm(false); // Скрываем форму добавления клиента
                // Обновляем новый таск с id нового клиента
                setNewTask({ ...newTask, clientId: data.id });
            })
            .catch(error => console.error('Error adding client:', error));
    };




    return (
        <Box display="flex" p={3}>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDateSelect} // Keep this for selecting the date
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                dayCellClassNames={(date) => {
                    // Apply custom class to the selected day
                    if (dayjs(date.date).format('YYYY-MM-DD') === selectedDate) {
                        return ['selected-day'];
                    }
                    return [];
                }}
            />
            <Box flex={1} marginLeft={3}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Время</TableCell>
                                {mechanics.length > 0 ? (
                                    mechanics.map((mechanic) => (
                                        <TableCell key={mechanic.id}>{mechanic.name}</TableCell>
                                    ))
                                ) : (
                                    <TableCell>Сотрудников не существует</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timeIntervals.map((time) => (
                                <TableRow key={time}>
                                    <TableCell>{time}</TableCell>
                                    {mechanics.map((mechanic) => (
                                        <TableCell
                                            key={mechanic.id}
                                            onClick={() => handleOpen(mechanic, time)} // Here only mechanic and time are passed
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {Array.isArray(events) && events // Ensure events is an array
                                                .filter(event => event.time === time) // Filter by time
                                                .map((event, eventIndex) => {
                                                    // console.log('Event found for time:', time, 'Mechanic:', mechanic.name, 'Tasks:', event.tasks); // Add log
                                                    return (
                                                        <Button key={eventIndex} variant="contained" color="primary" style={{ margin: '4px 0' }}>
                                                            {event.tasks
                                                                .filter(task => task.mechanic === mechanic.name)
                                                                .map((task, taskIndex) => (
                                                                    <span
                                                                        key={taskIndex}
                                                                        onClick={() => handleOpen(mechanic, time, task)} // Pass task here!
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                    {task.service} - {task.client} - {task.comment}
                  </span>
                                                                ))}
                                                        </Button>
                                                    );
                                                })}
                                        </TableCell>
                                    ))}
                                </TableRow>

                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Modal for adding new tasks */}
                <Modal open={open} onClose={handleClose}>
                    <Box
                        p={3}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            maxHeight: '80vh', // Set maximum height to 80% of the viewport height
                            overflowY: 'auto', // Enable vertical scrolling
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <h2>Редактирование задачи для {selectedCell?.mechanic?.name || "Выбрать сотрудника"} с {selectedCell?.time} на {selectedCell?.date}</h2>

                        {/* Time and Date */}
                        <TextField
                            label="Выбрать дату"
                            type="date"
                            value={newTask.date}  // Pre-filled with task date
                            onChange={(e) => setNewTask({ ...newTask, date: e.target.value })}
                            fullWidth
                            margin="normal"
                        />

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Выбрать время</InputLabel>
                            <Select
                                value={newTask.time}
                                onChange={(e) => setNewTask({ ...newTask, time: e.target.value })}
                            >
                                {timeIntervals.map((time, index) => (
                                    <MenuItem key={index} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Select Service */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Выбрать услугу</InputLabel>
                            <Select
                                value={newTask.serviceId} // Use serviceId instead of service
                                onChange={(e) => setNewTask({ ...newTask, serviceId: e.target.value })}
                            >
                                {services.map(service => (
                                    <MenuItem key={service.id} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Task Comment */}
                        <TextField
                            label="Комментарий"
                            fullWidth
                            value={newTask.comment}  // Pre-filled with task comment
                            onChange={(e) => setNewTask({ ...newTask, comment: e.target.value })}
                            margin="normal"
                        />

                        {/* Select Client */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Выбрать клиента</InputLabel>
                            <Select
                                value={newTask.clientId}
                                onChange={(e) => handleClientSelect(e.target.value)}
                            >
                                {clients.map(client => (
                                    <MenuItem key={client.id} value={client.id}>
                                        {client.name} - {client.phone_number}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Add New Client Section */}
                        <Button variant="outlined" onClick={() => setShowNewClientForm(true)} fullWidth>
                            Добавить нового клиента
                        </Button>

                        {showNewClientForm && (
                            <Box mt={2} p={2} bgcolor="whitesmoke">
                                <h3>Добавить нового клиента</h3>
                                <TextField
                                    label="Имя клиента"
                                    fullWidth
                                    value={newClient.name}
                                    onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="Номер телефона"
                                    fullWidth
                                    value={newClient.phoneNumber}
                                    onChange={(e) => setNewClient({ ...newClient, phoneNumber: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="Марка машины"
                                    fullWidth
                                    value={newClient.carBrand}
                                    onChange={(e) => setNewClient({ ...newClient, carBrand: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="Модель машины"
                                    fullWidth
                                    value={newClient.carModel}
                                    onChange={(e) => setNewClient({ ...newClient, carModel: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    label="Гос номер"
                                    fullWidth
                                    value={newClient.stateNumber}
                                    onChange={(e) => setNewClient({ ...newClient, stateNumber: e.target.value })}
                                    margin="normal"
                                />
                                <Button variant="contained" color="primary" fullWidth onClick={handleAddClient}>
                                    Добавить клиента
                                </Button>
                            </Box>
                        )}

                        {/* Save Changes */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveTask} // Call the handleSaveTask function
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Сохранить изменения
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClose} // Call the handleClose function
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Закрыть
                        </Button>

                        {selectedTaskId && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDeleteTask} // Call the handleDeleteTask function
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Удалить задачу
                            </Button>
                        )}
                    </Box>
                </Modal>


            </Box>
        </Box>
    )};

export default Calendar;